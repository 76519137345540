<template>
  <div class="nav-management">
    <b-row>
      <b-col cols="4">
        <b-card class="nav-management__card">
          <b-button
            id="button-create"
            href="#"
            block
            variant="outline-primary"
            class="d-flex justify-content-center mb-1"
            :disabled="isCreating"
            @click="create"
          >
            <b-spinner
              v-if="isCreating"
              variant="primary"
              class="d-flex"
              small
            />
            <template v-else>
              {{ buttonName }}
            </template>
          </b-button>
          <slot
            name="tooltip"
            :target="'button-create'"
          />
          <slot name="tooltip" />
          <div class="nav-management__list">
            <div
              v-for="(item, itemIndex) in items"
              :key="item.id"
            >
              <div
                :class="[
                  'tree__head',
                  { 'border-top': itemIndex !== 0 },
                ]"
              >
                <b-link
                  :to="{ name: routerLinkName, params: { id: item.id } }"
                  class="tree__link"
                >
                  <span class="align-middle">{{ item.name }}</span>
                </b-link>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <div
          v-if="$route.name === routeName && history.length"
          class="nav-history"
        >
          <div class="nav-history__head">
            {{ $t('Recently viewed') }}:
          </div>
          <div class="nav-history__body">
            <router-link
              v-for="historyItem in history"
              :key="historyItem.id"
              class="nav-history__item"
              :to="{ name: routerLinkName, params: { id: historyItem.id } }"
            >
              {{ historyItem.name }}
            </router-link>
          </div>
        </div>
        <router-view />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BLink,
  BSpinner,
} from 'bootstrap-vue'
import mixinNavManagementList from '@/mixins/nav-management-list'

export default {
  name: 'ZonesEvents',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
    BSpinner,
  },
  mixins: [
    mixinNavManagementList,
  ],
  props: {
    isCreating: {
      type: Boolean,
      required: true,
      default: false,
    },
    buttonName: {
      type: String,
      required: true,
      default: '',
    },
    history: {
      type: Array,
      required: true,
      default: () => [],
    },
    routeName: {
      type: String,
      required: true,
      default: '',
    },
    routerLinkName: {
      type: String,
      required: true,
      default: '',
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    create() {
      this.$emit('create')
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/tree.sass'
  @import '@/assets/sass/nav-history.sass'
</style>
