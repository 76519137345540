<template>
  <zones-events
    :items="events"
    :router-link-name="routerLinkName"
    route-name="events"
    :history="history"
    :button-name="`${$t('buttonNameNewEvent')}`"
    :is-creating="isCreating"
    @create="create"
  />
</template>

<script>
import ZonesEvents from '@/components/ZonesEvents.vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Events',
  components: {
    ZonesEvents,
  },
  data() {
    return {
      isCreating: false,
      routerLinkName: 'event',
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      navHistory: 'main/navHistory',
      isMenusFetched: 'menuManagement/isMenusFetched',
      isZonesFetched: 'floorPlan/isZonesFetched',
      isEventsFetched: 'floorPlan/isEventsFetched',
      isSchedulesFetched: 'schedules/isSchedulesFetched',
      events: 'floorPlan/events',
    }),
    history() {
      return this.navHistory.event
        .map(i => this.events.find(j => j.id === i))
        .filter(i => !!i)
    },
  },
  watch: {
    restaurant() {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    ...mapActions({
      getMenus: 'menuManagement/getMenus',
      getZones: 'floorPlan/getZones',
      getEvents: 'floorPlan/getEvents',
      getSchedules: 'schedules/getSchedules',
      createEvent: 'floorPlan/createEvent',
      setEvents: 'floorPlan/setEvents',
    }),
    fetch() {
      if (!this.restaurant) {
        return
      }

      if (!this.restaurant.eventsSettings.enabled) {
        this.$router.push({ name: 'dashboard' })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('notificationErrorNotAvailableAddon'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

      let getActions = []
      if (!this.isMenusFetched) {
        getActions = [...getActions, 'getMenus']
      }
      if (!this.isZonesFetched) {
        getActions = [...getActions, 'getZones']
      }
      if (!this.isEventsFetched) {
        getActions = [...getActions, 'getEvents']
      }
      if (!this.isSchedulesFetched) {
        getActions = [...getActions, 'getSchedules']
      }

      getActions.map(getAction => this[getAction]())
    },
    async create() {
      this.isCreating = true

      const event = await this.createEvent(this.$t('New Event'))

      // eslint-disable-next-line no-undef
      this.setEvents([...this.events, structuredClone(event)])

      await this.$router.push({
        name: this.routerLinkName,
        params: {
          id: event.id,
          isFocusOnName: true,
        },
      })

      this.isCreating = false
    },
  },
}
</script>
